@import "Variables";

.pupil-list-page {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  box-sizing: border-box;
  &.loading {
    height: 100%;
  }
  .name {
    width: 18%;
  }
  .number {
    width: 10%;
  }
  .corrector-1, .corrector-2 {
    width: 8%;
  }
  .rate-btn,.search-bars {
    width: 41%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
  .correction {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: center;
    width: 15%;
    .fa-icon {
      font-size: 22px;
    }
    .done {
      color: #90A959;
    }
    .empty {
      color: #A63D40;
    }
    .skipped {
      color: #888;
    }
  }
  .fields {
    display: flex;
    padding: 30px 30px 0 30px;
    gap: 20px;
    height: 35px;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
    color: $light-text-color;
    font-family: $font-family-Circular-Bold;
    .search-bars {
      margin-left: auto;
      gap: 10px;
      .search-by-number, .search-by-corrector {
        padding: 10px 25px;
        border: $border-light;
        border-radius: 25px;
        width: 100px;
        display: flex;
        align-items: center;
        input {
          border: none;
          outline: none;
          width: 100%;
          height: 25px;
          line-height: 25px;
        }
        .search-icon , .delete-icon {
          &:hover {
            color: $light-font;
            cursor: pointer;
          }
        }
        .delete-icon {
          margin-right: 5px;
        }
      }
      .get-by-corrector {
        padding: 10px 25px;
        border: $border-dark;
        background-color: $color2;
        border-radius: 25px;
        width: 100px;
        display: flex;
        align-items: center;
        input {
          border: none;
          background-color: $color2;
          outline: none;
          width: 100%;
          height: 25px;
          line-height: 25px;
        }
        .search-icon , .delete-icon {
          &:hover {
            color: $light-font;
            cursor: pointer;
          }
        }
        .delete-icon {
          margin-right: 5px;
        }
      }
    }

  }
  .pupil-row {
    border: $border-light;
    padding: 20px 30px;
    display: flex;
    grid-gap: 20px;
    gap: 20px;
    height: 35px;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
    font-family: $font-family-Circular-Bold;
    border-radius: 5px;
    .rate {
      margin-left: auto;
    }
    &.empty {
      justify-content: center;
    }
    &.loader {
      height: 100%;
      align-items: center;
      justify-content: center;
      font-size: 40px;
      color: $grey2
    }

  }
}