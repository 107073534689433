@import "Variables";

.auth-page {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .login-container {
    height: 100px;
    width: 300px;
    border: solid 1px #dedede;
    box-shadow: $box-shadow;
    display: flex;
    flex-direction: column;
    padding: 15px;
    .logo-container {
      height: 80px;
      width: 300px;
      .logo {
        width:100%;
      }
    }
  }
  .sign-in-container {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    height: 100%;
  }
}