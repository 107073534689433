@import "Variables";

.overlay {
  position: fixed;
  display: flex;
  width: 100%;
  height: 120%;
  background: rgba(0, 0, 0, .5);
  z-index: 1;
  align-items: center;
  justify-content: center;
  margin-top: -10%;

  mar .lds-ring {
    display: inline-block;
    position: relative;
    align-self: center;
    justify-self: center;
    width: 80px;
    height: 80px;
  }

  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid $color1;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: $color1 transparent transparent transparent;
  }

  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }

  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }

  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }

  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
}

.exam-container {
  display: flex;
  flex-direction: column;
  padding: 20px;


  .lds-ring {
    display: inline-block;
    position: relative;
    align-self: center;
    justify-self: center;
    width: 80px;
    height: 80px;
  }

  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid $color1;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: $color1 transparent transparent transparent;
  }

  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }

  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }

  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }

  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  .btn-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: $border-bottom;
    padding: 20px 0;
    gap: 15px;

    .go-back-btn {}

    .download-pdf-btn {
      margin-left: auto;
    }


  }

  .error-container {
    display: flex;
    align-items: center;
    font-weight: 600;
    height: 200px;
    justify-content: center;
  }

  .exam-info {
    padding: 20px;
    font-size: 20px;
    display: flex;
    font-family: $font-family-Circular-Bold;
    gap: 10px;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    border: $border;
    margin-top: 20px;



    .exam-name {
      font-family: $font-family-Circular-Book;
      font-size: 24px;
    }

    .info {
      margin-right: 20px;
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      width: 100%;

      .info-row {
        display: flex;
        flex-basis: 50%;
        justify-content: flex-start;
        flex-direction: row;
        align-items: center;
        gap: 10px;
        margin-bottom: 10px;

        .light-label {
          width: 125px;
        }
      }

      .name {}

      .id {}
    }

    .exam {
      display: flex;
      font-size: 24px;
    }
  }

  .table-content {
    width: 100%;
    margin: 40px 0;
    padding: 20px;
    box-sizing: border-box;
    border: $border;

    .test-rating {
      width: calc(50% + 64px);
      display: flex;
      flex-direction: row;
      margin-bottom: 15px;
      align-items: center;
      font-family: $font-family-Circular-Book;
      justify-content: flex-end;

      label {
        flex: 1;
        min-width: 100px;
      }

      .rating-dropdown {
        flex: 4;
      }
    }

    .test-comment {
      flex: 4;

      textarea {
        width: 100%;
        height: 100%;
        min-height: 100px;
        border: $border-light;
        font-family: $font-family-Circular-Book;
        font-weight: normal;
        padding: 12px 20px;
        box-sizing: border-box;
        font-size: 16px;
        resize: none;

        &:focus {
          outline: $border-dark;
        }
      }
    }
  }

}