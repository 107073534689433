@import "Variables";

.editor-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  gap: 15px;
  border: $border;
  padding: 20px;
  background-color: $dark-background;

  .test-order {
    font-size: 20px;
    font-weight: 600;
    font-family: $font-family-Circular-Bold;
  }

  .test-text {
    display: flex;
    flex-direction: row;

    .initial-text {
      width: 40%;
      height: 200px;

      textarea {
        width: 100%;
        height: 100%;
        border: $border-light;
        font-family: $font-family-Circular-Book;
        font-weight: normal;
        padding: 12px 20px;
        box-sizing: border-box;
        font-size: 16px;
        resize: none;

        &:focus {
          outline: $border-dark;
        }

        &:disabled {
          background-color: #999;
        }
      }
    }

    .parse-btn-container {
      width: 20%;
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 200px;
    }

    .latex-text {
      // width: 40%;
      width: 40%;
      height: 200px;
      overflow-y: scroll;
      overflow-x: hidden;
      border: $light-font;
      box-sizing: border-box;
      background: #ffffff;
      border: $border-light;
      padding: 20px;

      &.empty {
        padding: 0;
        width: 40%;
      }

      &.error {
        background: rgba($base-color, 0.07);
        padding: 10px 20px 20px 20px;

        .latex-error {
          font-size: 18px;
          font-weight: bold;
        }

        .latex-error-message {
          margin: 10px 0;
        }
      }

      .png-place-holder {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $grey;
      }

      .generated-png {
        width: fit-content;
        max-width: 100%;
      }

      img {
        width: 100%;
      }
    }

  }


  .image-row {
    display: inline-flex;
    flex-direction: row;
    align-items: start;
    justify-content: start;
    background-color: $background;
    padding: 10px;
    width: 100%;

    .button-column {
      display: inline-flex;
      flex-direction: column;
      align-items: flex-start;

      .button-column-spacer {
        height: 8px;
      }
    }



    .upload-row {
      display: flex;
      flex-direction: row;
      justify-content: start;
    }

    .image-upload-container {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 150px;

      #file-upload {
        display: none;
      }

      .file-upload-btn {


        &.loading {
          background: linear-gradient(to right, $color3 10%, #ffffff 10%, #ffffff);

          &.per40 {
            background: linear-gradient(to right, $color3 40%, #ffffff 40%, #ffffff);
          }

          &.per60 {
            background: linear-gradient(to right, $color3 60%, #ffffff 60%, #ffffff);
          }

          &.per80 {
            background: linear-gradient(to right, $color3 80%, #ffffff 80%, #ffffff);
          }

          &.per100 {
            background: linear-gradient(to right, $color1 100%, #ffffff 100%, #ffffff);
          }
        }

        .green {
          color: $green;
        }
      }
    }


    .image-container {
      margin-left: 50px;

      .image-drag-container {


        .dropped-files li {
          color: #07F;
          padding: 3px;
          text-align: left;
          font-weight: bold;
        }

        .image-drag-target {
          width: 300px;
          height: 150px;
          background-color: $grey;
          display: flex;
          align-items: center;
          justify-content: center;

        }

        .image-drag-target.inside-drag-area {
          background-color: $grey2;
        }
      }

      .image-display {
        max-height: 200px;
      }

      .image-placeholder {
        height: 200px;
        width: 200px;
        background-color: $grey;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }


}