$base-color: #e04d5d;
$base-color2: #d82034;
$base-color3: #a11c36;
$border-bottom: 1px solid #ccc;
$dark-background: #f9f9f9;
$light-font: #666;
$green: #46a10a;


$color1: #f19100;
$color2: #f5ad4e;
$color3: #f7bf75;
$color4: #f9d19a;
$color5: #fbe0bb;
$light-text-color: #898888;
$dark-text-color: #000000;
$grey: #EFEFEF;
$grey2: #ccc;
$background: #fff;
$border: 1px solid #f4f4f4;
$border-light: solid 1px #dedede;
$border-dark: solid 1px #a2a2a2;
$box-shadow: 0 0.1875rem 0.375rem 0 rgba(0, 0, 0, 0.1);
$font-family-Circular-Bold: CircularBold,arial,helvetica,sans-serif;
$font-family-Circular-Book: CircularBook,arial,helvetica,sans-serif;
$logo: url('./assets/logo.png');

@font-face {
  font-family: CircularBold;
  src: url('./fonts/CircularXXWeb-Bold.woff');
}

@font-face {
  font-family: CircularBook;
  src: url('./fonts/CircularXXWeb-Book.woff');
}