.add-student-container {
  padding: 20px;
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  gap: 20px;
  align-items: center;
  .add-student-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 65%;
    &.button-row {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      gap: 20px;
    }
    label {
      width: 30%;
    }
    input {
      border: solid 1px #dedede;
      border-radius: 5px;
      color: #000;
      padding: 20px 30px;
      width: 70%;
      box-shadow: none;
      outline: none;
    }
  }
}