@import "Variables";

.header {
  align-items: center;
  background-color: transparent;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 32px 40px;
  position: relative;
  box-shadow: $box-shadow;
  .logo-container {
    height: 54px;
    width: 30%;
    min-width: 370px;
    .logo {
      //background-image: './assets/logo.png';
    }

  }
  .btn-container {
    margin-left: auto;
    display: flex;
    flex-direction: revert;
    gap: 15px;
    #file-upload {
      display: none;
    }
    .file-upload-btn {
      &.loading {
        transition: background 1s ease-out;
        background: linear-gradient(to right, $color3 10%, #ffffff 10%, #ffffff);
        &.per40 {
          background: linear-gradient(to right, $color3 40%, #ffffff 40%, #ffffff);
        }
        &.per60 {
          background: linear-gradient(to right, $color3 60%, #ffffff 60%, #ffffff);
        }
        &.per80 {
          background: linear-gradient(to right, $color3 80%, #ffffff 80%, #ffffff);
        }
        &.per100 {
          background: linear-gradient(to right, $color1 100%, #ffffff 100%, #ffffff);
        }
      }
      .green {
        color: $green;
      }
    }
  }
  .error-container {
    position: absolute;
    top: 0;
    background: #ffffff;
    border-top: none;
    color: $base-color2;
    font-size: 23px;
    width: calc(100% - 80px);
    text-align: center;
  }
}