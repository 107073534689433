@import "Variables";

html,
body,
#root {
  height: 100%;
  margin: 0;
  padding: 0;
}

.app {
  height: 100%;
  .container {
    height: 100%;
    display: flex;
    flex-direction: column;
    .content {
      flex: 1;
    }
  }
}
.btn {
  padding: 10px 25px;
  border-radius: 23px;
  font-family: $font-family-Circular-Bold;
  font-size: 16px;
  cursor: pointer;
  position: relative;
  color: #ffff;
  font-weight: bold;
  caret-color: transparent;
  .fa-icon {
    margin-right: 10px;
  }
  .spinner {
    font-size: larger;
    position: absolute;
    left: 0;
    width: 100%;
  }
  &::after {
    background-color: #fff;
    bottom: 0;
    content: '';
    border-radius: 23px;
    display: inline-block;
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    transition: .35s;
    visibility: hidden;
    width: 10%;
  }
  &:hover {
    &:after {
      opacity: .2;
      visibility: visible;
      width: 100%;
    }
  }
  &.orange-btn {
    background-color: $color1;
  }
  &.outline-btn {
    border: $border-light;
    color: #000;
    background-color: $background;
    &::after {
      background-color: $color4;
    }
    &:hover {
      background-color: $color1;
      color: #fff;
    }
  }
  &.grey-btn {
    border: $color2;
    background-color: $grey;
    color: #000;
    &:hover {
      background-color: $color1;
      color: #fff;
    }
    &:disabled {
      color: $grey2;
    }
    .spinner {
      color: #000;
    }
  }
  &.delete-btn {
    background-color: $grey;
    color: #000;
    &:hover {
      background-color: $base-color;
      color: #fff;
    }
  }
}

.logo {
  height: 100%;
  width: 370px;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: $logo;
}

.light-label {
  font-family: $font-family-Circular-Book;
  color: $light-text-color;
  font-size: 18px;
}

.dark-label {
  font-family: $font-family-Circular-Book;
  color: $dark-text-color;
  font-size: 18px;
}

.confirmation-modal {
  margin-right: -50%;
  transform: translate(-50%, -50%);
  border: $border-light;
  border-radius: 5px;
  box-shadow: $box-shadow;
  width: 400px;
  position: absolute;
  inset: 50% auto auto 50%;
  background: rgb(255, 255, 255);
  overflow: auto;
  outline: none;
  padding: 20px;
  .modal-title {
    font-size: 20px;
    font-weight: bold;
  }
  .modal-text {
    margin: 20px 0;
  }
  .modal-buttons-container {
    gap: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}
